const config = {
  REACT_APP_WOMPI_API_PUBLIC: process.env.REACT_APP_WOMPI_API_PUBLIC,
  REACT_APP_WOMPI_API_SECRET: process.env.REACT_APP_WOMPI_API_SECRET,
  REACT_APP_INTEGRITY_KEY: process.env.REACT_APP_INTEGRITY_KEY,
  REACT_APP_PAYPAL_PUBLIC_KEY: process.env.REACT_APP_PAYPAL_PUBLIC_KEY,
  REACT_APP_PAYPAL_SECRET_KEY: process.env.REACT_APP_PAYPAL_SECRET_KEY,
  REACT_APP_STRIPE_PUBLIC_KEY_API: process.env.REACT_APP_STRIPE_PUBLIC_KEY_API,
  REACT_APP_STRIPE_SECRET_KEY_API: process.env.REACT_APP_STRIPE_SECRET_KEY_API,
  REACT_APP_EMAIL_USER: process.env.REACT_APP_EMAIL_USER,
  REACT_APP_EMAIL_PASS: process.env.REACT_APP_EMAIL_PASS
  };
  
export default config;
  