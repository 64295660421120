import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

let interval;

export const CardStack = ({ items, offset, scaleFactor }) => {
  const CARD_OFFSET = offset || 10;
  const SCALE_FACTOR = scaleFactor || 0.06;
  const [cards, setCards] = useState(items);

  useEffect(() => {
    startFlipping();

    return () => clearInterval(interval);
  }, []);

  const startFlipping = () => {
    interval = setInterval(() => {
      setCards((prevCards) => {
        const newArray = [...prevCards];
        newArray.unshift(newArray.pop());
        return newArray;
      });
    }, 5000);
  };

  return (
    <div className="relative h-60 w-60 md:h-60 md:w-96">
      {cards.map((card, index) => (
        <motion.div
          key={card.id}
          className="absolute bg-transparent backdrop-blur-3xl h-60 w-60 md:h-60 md:w-96 rounded-3xl p-4 shadow-xl   shadow-black/[0.1] dark:shadow-white/[0.05] flex flex-col justify-between"
          style={{
            transformOrigin: "top center",
          }}
          animate={{
            top: index * -CARD_OFFSET,
            scale: 1 - index * SCALE_FACTOR,
            zIndex: cards.length - index,
          }}
        >
          <div className="font-normal text-neutral-800">
            {card.content}
          </div>
          <div>
            <p className="text-neutral-500 font-medium text-neutral-800">
              {card.name}
            </p>
            <p className="text-neutral-400 font-normal ">
              {card.designation}
            </p>
          </div>
        </motion.div>
      ))}
    </div>
  );
};
